<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/activity?id=2' }"
          >公共政策</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          title.substring(0, 6) + "..."
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <!-- 无title -->
      <div class="box" v-if="wzid == '1784897'">
        <div class="p_box">
          <p>
            根据《财政部 税务总局
            工业和信息化部关于设有固定装置的非运输专用作业车辆免征车辆购置税有关政策的公告》（2020年第35号）、《国家税务总局
            工业和信息化部关于设有固定装置的非运输专用作业车辆免征车辆购置税有关管理事项的公告》（2020年第20号）相关规定，现将《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）予以发布。
          </p>
          <p>特此公告。</p>
          <p>
            附件：<a
              href="../../assets/file/1701137174045046596.doc"
              download
              style="color: #055afe"
              >免征车辆购置税的设有固定装置的非运输专用作业车辆目录（第十二批）.doc</a
            >
          </p>
        </div>
      </div>
      <div class="box" v-if="wzid == '1784441'">
        <p>
          为进一步改善用人单位和个人办理社会保险费申报缴纳业务的服务体验，降低办事成本，保障用人单位和个人合法权益，山西省自2023年12月1日起，优化调整用人单位和灵活就业人员（以下简称“缴费人”）社会保险费申报缴纳流程。现将有关事项公告如下：
        </p>
        <p>
          <span class="title">一、优化调整申报缴费流程内容</span>
          <span>
            自2023年12月1日起，将缴费人现行的社会保险费申报缴纳流程优化调整为缴费人自行向税务部门申报缴费（以下简称“自行申报”）。具体范围是：
          </span>
          <span>
            （一）用人单位（含企业、机关事业单位、民办非企业单位、社会团体、有雇工的个体工商户等）及其职工缴纳的企业职工基本养老保险费、机关事业单位基本养老保险费、职工基本医疗保险费（含生育保险费）、工伤保险费、失业保险费，职业年金以及其他补充保险费。
          </span>
          <span>
            （二）灵活就业人员（含无雇工的个体工商户、未在用人单位参加基本养老保险、职工基本医疗保险的非全日制从业人员及其他灵活就业人员等）缴纳的企业职工基本养老保险费、职工基本医疗保险费（含大病保险）。
          </span>
        </p>
        <p>
          <span class="title">二、申报缴费方式及时限</span>
          <span>
            （一）缴费人自行向税务部门申报缴纳社会保险费。社会保险费缴费基数和应缴费额继续按照现行计算方式确定。
          </span>
          <span> （二）用人单位向税务部门申报、调整职工缴费工资。 </span>
          <span>
            2023年已向人力资源社会保障、医疗保障部门申报职工缴费工资并核定当年缴费基数的用人单位，无需再进行2023年缴费工资申报；未完成2023年职工缴费工资申报的用人单位，向税务部门申报本年度缴费工资。
          </span>
          <span>
            2023年12月1日后，用人单位向人力资源社会保障、医疗保障部门办理新增职工的社会保险参保登记后，于参保登记当月底前向税务部门申报当年度缴费工资。
          </span>
          <span>
            用人单位应当于每月1-25日（节假日不顺延）自行向税务部门申报确认应缴费额并缴纳社会保险费，职工个人缴费部分由用人单位代扣代缴。
          </span>
          <span>
            （三）灵活就业人员可以按年（季、月）自行向税务部门申报缴纳社会保险费。
          </span>
          <span>
            （四）缴费人存在补缴未办理社会保险登记产生的欠费、2023年12月1日前社会保险费欠费，以及办理政策性补缴等特殊情形的，仍应当先向人力资源社会保障、医疗保障部门申报，经人力资源社会保障、医疗保障部门核定生成应缴费额后，再向税务部门缴纳社会保险费。
          </span>
        </p>
        <p>
          <span class="title">三、申报缴费渠道</span>
          <span>
            2023年12月1日后，已在人力资源社会保障、医疗保障部门完成参保登记的缴费人在税务部门办理申报缴费业务，建议缴费人优先选择线上渠道办理缴费事项。
          </span>
          <span>
            用人单位可通过山西省电子税务局、社保费管理客户端、办税服务厅、政务服务厅办税窗口办理社会保险费缴费工资申报、应缴费额申报确认和缴纳业务。
          </span>
          <span>
            灵活就业人员可通过微信、银行渠道（云缴费APP、协作银行手机APP、银联扫码）、云闪付APP、支付宝、电子税务局、办税服务厅、政务服务厅办税窗口办理社会保险费缴费基数申报、应缴费额申报确认和缴纳业务。
          </span>
        </p>
        <p>
          <span class="title">四、其他事项</span>
          <span>
            （一）社会保险登记、权益记录、待遇支付等业务继续在人力资源社会保障、医疗保障部门办理。
          </span>
          <span>
            （二）缴费人在办理申报缴费业务时如有疑问，可拨打12366-6纳税缴费服务热线咨询；其他社会保险问题（如：参保登记、关系转续、待遇核定等）可拨打12333人力资源社会保障服务热线或者12345政务服务便民热线社会保险专席咨询。
          </span>
          <span> 特此公告。 </span>
        </p>
      </div>
      <!-- 单title -->
      <div class="box" v-if="wzid == '1784320'">
        <p>
          为贯彻中办、国办印发的《关于进一步深化税收征管改革的意见》精神，税务部门不断拓展“非接触式”“不见面”办税缴费服务，扩大跨省经营企业全国通办涉税涉费事项范围，持续改进办税缴费方式，切实减轻办税缴费负担，更好服务经营主体发展，并根据最新政策及业务调整情况，及时更新《“非接触式”办税缴费事项清单》。现将更新后可在网上办理的233项“非接触式”办税缴费事项予以公布。
        </p>
        <p>
          在此基础上，税务部门还将进一步依托电子税务局、手机APP、邮寄、传真、电子邮件等方式，不断拓宽“非接触式”办税缴费渠道，对部分复杂事项通过线上线下结合办理的方式，更好地为纳税人、缴费人服务。
        </p>
        <p>
          <img src="../../assets/file/1700015816247056003.png" alt="" />
        </p>
      </div>
      <div class="box" v-if="wzid == '1784647'">
        <p>
          根据《中华人民共和国企业所得税法》及《中华人民共和国企业所得税法实施条例》有关规定，按照《财政部 税务总局关于通过公益性群众团体的公益性捐赠税前扣除有关事项的公告》（财政部 税务总局公告2021年第20号）有关要求，现将2024年度—2026年度符合公益性捐赠税前扣除资格的群众团体名单公告如下： 
        </p>
        <p>1.中国红十字会总会</p>
        <p>2.中华全国总工会</p>
        <p>3.中国宋庆龄基金会</p>
        <p>4.中国国际人才交流基金会</p>
      </div>
      <div class="box" v-if="wzid == '1784411'">
        <p>
          各市、县（市、区）财政局，国家税务总局山西省各市、县（市、区）税务局、国家税务总局山西省综改区税务局：
        </p>
        <p>
          为进一步加强和规范我省非营利组织免税资格认定管理工作，依据《财政部
          税务总局关于非营利组织免税资格认定管理有关问题的通知》（财税〔2018〕13号，以下简称财税〔2018〕13号）有关规定，现将有关事项通知如下：
        </p>
        <p>
          <span class="title">一、认定权限</span>
          <span>
            非营利组织免税资格认定工作分别由省级、市级和县级的财政部门会同本级税务机关联合办理，分别负责省级（含省级以上）、市级和县级登记管理机关批准设立或登记的非营利组织免税资格的认定、复审和复核工作。
          </span>
        </p>
        <p>
          <span class="title">二、认定对象</span>
          <span>
            在我省行政区域内经登记管理机关批准设立或登记的非营利组织，凡符合财税〔2018〕13号第一条规定条件，在税务机关登记并按规定办理企业所得税申报及其他涉税事宜的，均可申请确认非营利组织免税资格。
          </span>
        </p>
        <p>
          <span class="title">三、认定流程</span>
          <span> （一）申请时间 </span>
          <span>
            初次申请免税资格的非营利组织在11月1日至12月31日提出申请。免税资格期满的非营利组织，可在期满后半年内提出申请。
          </span>
          <span> （二）报送资料 </span>
          <span>
            申请免税资格的非营利组织应按照财税〔2018〕13号第三条规定报送相关资料，具体内容详见附件《山西省非营利组织免税资格认定申请资料清单及要求》，申请人须对报送资料的真实性负法律责任。
          </span>
          <span> （三）报送方式 </span>
          <span>
            申请免税资格的非营利组织可通过山西省电子税务局递交免税资格申请相关资料，根据批准设立或登记的管理机关层级，具体选择对应省级/市级/县级“非营利组织免税资格认定”模块办理。
          </span>
          <span>
            无法通过网上办理的，申请人可根据批准设立或登记的管理机关层级，向具有相应认定权限的税务机关企业所得税主管部门递交纸质申请资料。
          </span>
          <span> （四）资格确认 </span>
          <span>
            各级税务机关按照财税〔2018〕13号有关规定，对申报材料的完整性和相关税务事项进行审核，并于次年1月底前将税务机关意见和申报资料反馈同级财政部门。未通过审核的，应及时书面通知相关社会组织并告知原因。
          </span>
          <span>
            各级财政部门在收到税务机关意见和申报资料后，应于5个工作日内将税务审核通过的非营利组织名单交由登记管理机关进行审核。登记管理机关应于5个工作日内完成审核，并反馈财政部门。根据登记管理机关反馈结果，财政部门、税务机关在申请材料提交次年2月底前下达资格确认文件。
          </span>
          <span> （五）结果公布 </span>
          <span>
            经认定符合享受免税资格条件的非营利组织名单，按认定权限在本级财政厅（局）门户网站、税务局门户网站予以公布。无门户网站的财政、税务部门也应以其他方式公开认定结果。申请免税资格的非营利组织可于申请次年3月1日起查询认定结果。
          </span>
          <span>
            申请人对认定结果有异议的，应于结果公布之日起15日内提出复核申请并提供相应证据资料。
          </span>
        </p>
        <p>
          <span class="title">四、税收优惠办理</span>
          <span>
            取得免税资格的非营利组织符合《财政部 国家税务总局关于非营利组织企业所得税免税收入问题的通知》（财税〔2009〕122号）规定的免税收入，可采取“自行判别、申报享受、相关资料留存备查”的方式享受税收优惠，企业所得税季度预缴、年度汇算清缴均可享受。取得上述免税收入以外的其他收入，应当依法缴纳企业所得税。
          </span>
        </p>
        <p>
          <span class="title">五、后续管理</span>
          <span>
            各级财政、税务部门要加强对非营利组织的政策宣传，辅导其按规定进行纳税申报。
          </span>
          <span>
            取得免税资格的非营利组织，在免税资格有效期内更改名称的，应及时主动向原申请机关报告，并提交相关资料。仍符合免税资格条件的，免税资格有效期延续；未报告或不再符合免税资格条件的，免税资格自动失效。
          </span>
          <span>
            本通知自发布之日起施行。《山西省财政厅 山西省国家税务局 山西省地方税务局关于转发〈财政部 税务总局关于非营利组织免税资格认定管理有关问题的通知〉的通知》（晋财税〔2018〕6号）同时废止。
          </span>
        </p>
        <p>
          附件：<a
            href="../../assets/file/1701071416120070866.doc"
            download
            style="color: #055afe"
            >山西省非营利组织免税资格认定申请资料清单及要求.doc</a
          >
        </p>
      </div>
      <div class="box" v-if="wzid == '1783525'">
        <p>
          国家消防救援局，各省、自治区、直辖市、计划单列市财政厅（局），新疆生产建设兵团财政局，海关总署广东分署、各直属海关，国家税务总局各省、自治区、直辖市、计划单列市税务局：
        </p>
        <p>
          <span>
            为支持国家综合性消防救援队伍建设，现将有关进口税收政策通知如下：
          </span>
          <span>
            一、自2023年1月1日至2025年12月31日，对国家综合性消防救援队伍进口国内不能生产或性能不能满足需求的消防救援装备，免征关税和进口环节增值税、消费税。
          </span>
          <span>
            二、享受免税政策的装备列入《消防救援装备进口免税目录》（见附件1）。该目录由财政部会同海关总署、税务总局、国家消防救援局、工业和信息化部根据消防救援任务需求和国内产业发展情况适时调整。
          </span>
          <span>
            三、国家消防救援局对国家综合性消防救援队伍各级队伍进口列入《消防救援装备进口免税目录》的装备出具《国家综合性消防救援队伍进口消防救援装备确认表》（见附件2）。国家综合性消防救援队伍各级队伍凭《国家综合性消防救援队伍进口消防救援装备确认表》，按相关规定向海关申请办理消防救援装备进口免税手续。
          </span>
          <span>
            四、自2023年1月1日至本通知印发前，国家综合性消防救援队伍已进口的装备所缴纳的进口税款，符合本政策规定的，依申请准予退还。
          </span>
          <span>
            五、国家消防救援局会同有关部门制定《免税进口消防救援装备管理办法》，明确进口单位条件，以及免税消防救援装备进口后登记、使用、管理要求。
          </span>
          <span>
            六、国家消防救援局每年对上一年度的政策执行情况进行总结评估，汇总分析进口装备货值和免税额、政策执行效果、存在问题等，并对主要进口装备分类、分单位进行统计，以上形成报告于每年3月31日前函告财政部，抄送海关总署、税务总局。
          </span>
        </p>
        <p>
          <span> 附件： </span>
          <span
            >1.<a
              href="../../assets/file/1698313075845087835.pdf"
              style="color: #055afe"
              >消防救援装备进口免税目录.pdf</a
            ></span
          >
          <span
            >2.<a
              href="../../assets/file/1698313086733020155.pdf"
              style="color: #055afe"
              >消防救援装备进口免税目录.pdf</a
            ></span
          >
        </p>
      </div>
      <div class="box" v-if="wzid == '1783448'">
        <div class="p_box">
          <p>
            <span>
              一、经营性文化事业单位转制为企业，可以享受以下税收优惠政策：
            </span>
            <span>
              （一）经营性文化事业单位转制为企业，自转制注册之日起五年内免征企业所得税。
            </span>
            <span>
              （二）由财政部门拨付事业经费的文化单位转制为企业，自转制注册之日起五年内对其自用房产免征房产税。
            </span>
            <span>
              （三）党报、党刊将其发行、印刷业务及相应的经营性资产剥离组建的文化企业，自注册之日起所取得的党报、党刊发行收入和印刷收入免征增值税。
            </span>
            <span>
              （四）对经营性文化事业单位转制中资产评估增值、资产转让或划转涉及的企业所得税、增值税、城市维护建设税、契税、印花税等，符合现行规定的享受相应税收优惠政策。
            </span>
            <span>
              上述所称“经营性文化事业单位”，是指从事新闻出版、广播影视和文化艺术的事业单位。转制包括整体转制和剥离转制。其中，整体转制包括：（图书、音像、电子）出版社、非时政类报刊出版单位、新华书店、艺术院团、电影制片厂、电影（发行放映）公司、影剧院、重点新闻网站等整体转制为企业；剥离转制包括：新闻媒体中的广告、印刷、发行、传输网络等部分，以及影视剧等节目制作与销售机构，从事业体制中剥离出来转制为企业。
            </span>
            <span>
              上述所称“转制注册之日”，是指经营性文化事业单位转制为企业并进行企业法人登记之日。对于经营性文化事业单位转制前已进行企业法人登记，则按注销事业单位法人登记之日，或核销事业编制的批复之日（转制前未进行事业单位法人登记的）确定转制完成并享受本公告所规定的税收优惠政策。
            </span>
          </p>
          <p>
            <span>
              二、享受税收优惠政策的转制文化企业应同时符合以下条件：
            </span>
            <span> （一）根据相关部门的批复进行转制。 </span>
            <span> （二）转制文化企业已进行企业法人登记。 </span>
            <span>
              （三）整体转制前已进行事业单位法人登记的，转制后已核销事业编制、注销事业单位法人；整体转制前未进行事业单位法人登记的，转制后已核销事业编制。
            </span>
            <span>
              （四）已同在职职工全部签订劳动合同，按企业办法参加社会保险。
            </span>
            <span>
              （五）转制文化企业引入非公有资本和境外资本的，须符合国家法律法规和政策规定；变更资本结构依法应经批准的，需经行业主管部门和国有文化资产监管部门批准。
            </span>
            <span>
              本公告适用于所有转制文化单位。中央所属转制文化企业的认定，由中央宣传部会同财政部、税务总局确定并发布名单；地方所属转制文化企业的认定，按照登记管理权限，由地方各级宣传部门会同同级财政、税务部门确定和发布名单，并按程序抄送中央宣传部、财政部和税务总局。
            </span>
            <span>
              已认定发布的转制文化企业名称发生变更的，如果主营业务未发生变化，可持同级党委宣传部门出具的同意变更函，到主管税务机关履行变更手续；如果主营业务发生变化，依照本条规定的条件重新认定。
            </span>
            <span>
              三、经认定的转制文化企业，应按有关税收优惠事项管理规定办理优惠手续，申报享受税收优惠政策。企业应将转制方案批复函，企业营业执照，同级机构编制管理机关核销事业编制、注销事业单位法人的证明，与在职职工签订劳动合同、按企业办法参加社会保险制度的有关材料，相关部门对引入非公有资本和境外资本、变更资本结构的批准文件等留存备查，税务部门依法加强后续管理。
            </span>
            <span>
              四、未经认定的转制文化企业或转制文化企业不符合本公告规定的，不得享受相关税收优惠政策。已享受优惠的，主管税务机关应追缴其已减免的税款。
            </span>
            <span>
              五、对已转制企业按照本公告规定应予减免的税款，在本公告下发以前已经征收入库的，可抵减以后纳税期应缴税款或办理退库。
            </span>
            <span>
              六、本公告规定的税收政策执行至2027年12月31日。企业在2027年12月31日享受本公告第一条第（一）、（二）项税收政策不满五年的，可继续享受至五年期满为止。
            </span>
            <span> 特此公告。 </span>
          </p>
        </div>
      </div>
      <div class="box" v-if="wzid == '1782798'">
        <p>
          各省、自治区、直辖市、计划单列市财政厅（局），新疆生产建设兵团财政局，国家税务总局各省、自治区、直辖市、计划单列市税务局：
        </p>
        <p>
          <span>
            根据海洋油（气）企业经营变化情况，现就销售或租赁海洋工程结构物适用出口退税政策有关事项通知如下：
          </span>
          <span>
            一、对《财政部
            国家税务总局关于出口货物劳务增值税和消费税政策的通知》（财税〔2012〕39号）附件3中“二、海上石油天然气开采企业的具体范围”所列企业进行调整，具体如下：
          </span>
          <span>
            （一）在“（一）中国海洋石油总公司及其下属企业”项下企业中删除中海石油（中国）有限公司荔湾作业公司、中海石油（中国）有限公司文昌13-1/2油田作业公司。
          </span>
          <span>
            （二）在“（二）中国海洋石油对外合作公司”项下企业中增加博道长和石油有限公司、哈斯基石油作业（中国）有限公司、爱思开新技术株式会社和爱思开尔世恩株式会社，并删除台南-潮汕石油作业有限公司。
          </span>
          <span>
            （三）对“（四）中国石油化工集团公司下属企业”项下企业名称进行变更：将“胜利石油管理局海洋钻井公司”调整为“中石化胜利石油工程有限公司海洋钻井公司”；将“中国石化集团上海海洋石油局”调整为“中国石化集团上海海洋石油局有限公司”；将“上海海洋石油勘探开发总公司”调整为“中石化海洋石油工程有限公司”。
          </span>
        </p>
        <p>
          二、《财政部 国家税务总局关于明确金融 房地产开发
          教育辅助服务等增值税政策的通知》（财税〔2016〕140号）第十七条“中外合作油（气）田开采企业”按上述调整后的企业名单执行。
        </p>
        <p>三、本通知自上述企业设立登记、注销登记或变更登记之日起执行。</p>
      </div>
      <div class="box" v-if="wzid == '1782455'">
        <p>
          第九批全国税务领军人才学员选拔工作已完成个人报名、推荐、笔试、素质和业绩评价、面试工作环节。经全国税务领军人才培养工作领导小组研究，共确定129人作为预录取考察对象（名单见附件）。
        </p>
        <p>
          近期，国家税务总局人事司将组成考察组，对考察对象的德、能、勤、绩、廉等现实表现进行全面考察，请被考察对象所在单位提前做好相关准备工作。具体考察时间，由考察组通知相关单位。
        </p>
        <p>
          考察期间如有问题和意见，请及时向全国税务领军人才培养工作领导小组办公室反馈。
        </p>
        <p>
          联系人：国家税务总局人事司人才管理处
          郭晓进，联系电话：010-63417822；国家税务总局教育中心领军人才培养处
          姜涛，联系电话：010-61986655。
        </p>
        <p>邮政地址：北京市海淀区羊坊店西路5号，邮编：100038。</p>
        <p>特此通告。</p>
        <p>
          附件：<a
            href="../../assets/file/1696663501404009915.doc"
            download
            style="color: #055afe"
            >第九批全国税务领军人才学员选拔预录取考察对象名单.doc</a
          >
        </p>
      </div>
      <div class="box" v-if="wzid == '1782453'">
        <p>为推进保障性住房建设，现将有关税费政策公告如下：</p>
        <p>
          <span>
            一、对保障性住房项目建设用地免征城镇土地使用税。对保障性住房经营管理单位与保障性住房相关的印花税，以及保障性住房购买人涉及的印花税予以免征。
          </span>
          <span>
            在商品住房等开发项目中配套建造保障性住房的，依据政府部门出具的相关材料，可按保障性住房建筑面积占总建筑面积的比例免征城镇土地使用税、印花税。
          </span>
          <span>
            二、企事业单位、社会团体以及其他组织转让旧房作为保障性住房房源且增值额未超过扣除项目金额20%的，免征土地增值税。
          </span>
          <span>
            三、对保障性住房经营管理单位回购保障性住房继续作为保障性住房房源的，免征契税。
          </span>
          <span> 四、对个人购买保障性住房，减按1%的税率征收契税。 </span>
          <span>
            五、保障性住房项目免收各项行政事业性收费和政府性基金，包括防空地下室易地建设费、城市基础设施配套费、教育费附加和地方教育附加等。
          </span>
          <span>
            六、享受税费优惠政策的保障性住房项目，按照城市人民政府认定的范围确定。城市人民政府住房城乡建设部门将本地区保障性住房项目、保障性住房经营管理单位等信息及时提供给同级财政、税务部门。
          </span>
          <span>
            七、纳税人享受本公告规定的税费优惠政策，应按相关规定申报办理。
          </span>
          <span> 八、本公告自2023年10月1日起执行。 </span>
          <span> 特此公告。 </span>
        </p>
      </div>
      <div class="box" v-if="wzid == '1782451'">
        <p>
          为继续支持促进资源综合利用和环境保护，现对以回收的废矿物油为原料生产的润滑油基础油、汽油、柴油等工业油料免征消费税政策公告如下：
        </p>
        <p>
          <span>
            一、废矿物油，是指工业生产领域机械设备及汽车、船舶等交通运输设备使用后失去或降低功效更换下来的废润滑油。
          </span>
          <span>
            二、纳税人利用废矿物油生产的润滑油基础油、汽油、柴油等工业油料免征消费税，应同时符合下列条件：
          </span>
          <span>
            （一）纳税人必须取得生态环境部门颁发的《危险废物（综合）经营许可证》，且该证件上核准生产经营范围应包括“利用”或“综合经营”字样。生产经营范围为“综合经营”的纳税人，还应同时提供颁发《危险废物（综合）经营许可证》的生态环境部门出具的能证明其生产经营范围包括“利用”的材料。
          </span>
          <span>
            纳税人在申请办理免征消费税备案时，应同时提交污染物排放地生态环境部门确定的该纳税人应予执行的污染物排放标准，以及污染物排放地生态环境部门在此前6个月以内出具的该纳税人的污染物排放符合上述标准的证明材料。
          </span>
          <span>
            纳税人回收的废矿物油应具备能显示其名称、特性、数量、接受日期等项目的《危险废物转移联单》。
          </span>
          <span>
            （二）生产原料中废矿物油重量必须占到90%以上。产成品中必须包括润滑油基础油，且每吨废矿物油生产的润滑油基础油应不少于0.65吨。
          </span>
          <span>
            （三）利用废矿物油生产的产品与利用其他原料生产的产品应分别核算。
          </span>
          <span>
            三、符合本公告第二条规定的纳税人销售免税油品时，应在增值税专用发票上注明产品名称，并在产品名称后加注“（废矿物油）”。
          </span>
          <span>
            四、符合本公告第二条规定的纳税人利用废矿物油生产的润滑油基础油连续加工生产润滑油，或纳税人（包括符合本公告第二条规定的纳税人及其他纳税人）外购利用废矿物油生产的润滑油基础油加工生产润滑油，在申报润滑油消费税额时按当期销售的润滑油数量扣减其耗用的符合本公告规定的润滑油基础油数量的余额计算缴纳消费税。
          </span>
          <span>
            五、对未达到相应的污染物排放标准或被取消《危险废物（综合）经营许可证》的纳税人，自发生违规排放行为之日或《危险废物（综合）经营许可证》被取消之日起，取消其享受本公告规定的免征消费税政策的资格，且三年内不得再次申请。纳税人自发生违规排放行为之日起已申请并办理免税的，应予追缴。
          </span>
          <span>
            六、各级税务机关应采取严密措施，对享受本公告规定的免征消费税政策的纳税人加强动态监管。凡经核实纳税人弄虚作假骗取享受本公告规定的免征消费税政策的，税务机关追缴其此前骗取的免税税款，并自纳税人发生上述违法违规行为年度起，取消其享受本公告规定的免征消费税政策的资格，且纳税人三年内不得再次申请。
          </span>
          <span>
            发生违规排放行为之日，是指已由污染物排放地生态环境部门查证确认的、纳税人发生未达到应予执行的污染物排放标准行为的当日。
          </span>
          <span>
            七、本公告执行至2027年12月31日。
          </span>
        </p>
      </div>
    </div>
    <!-- <div class="btn" @click="subInfo">立即体验</div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      wzid: 0,
      title: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "cw" },
      });
    },
  },
  created() {
    this.title = sessionStorage.getItem("hdtitle");
    this.wzid = this.$route.query.id;
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  text-indent: 2em;
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
}

.box p:first-child {
  padding: 0;
}

/* .box p.p_box {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
} */

.box p span.title {
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.box p span.center_title {
  width: 100%;
  text-align: center;
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.box p span {
  display: block;
  margin: 10px 0;
}

.box p img {
  display: block;
  width: 70%;
  margin: 0 auto;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}
</style>
